import React, {useState, useEffect} from 'react';

import "../../styles/matchstatus.css";

import {
  Segment, Grid, Image, Icon
} from 'semantic-ui-react'

import Constants from "../../constants/constants";

const BetDistribution = ({
  xHome, xTie, xAway,
  matchDisplay, getSlotBetOption, getSlotFullName, getSlotCrestURI, setTeamButtonStyle, isEndOfSegment
}) =>
{
  const xTotal = (
    (xHome ? xHome : 0) +
    (xTie ? xTie : 0) +
    (xAway ? xAway : 0)
  );
  const [homeWidth, setHomeWidth] = useState(0);
  const [tieWidth, setTieWidth] = useState(0);
  const [awayWidth, setAwayWidth] = useState(0);

  // Array with displays that have the away team first
  const MATCH_DISPLAYS_AWAY_FIRST = [
    Constants.MATCH_DISPLAY__AWAY_X_HOME,
    Constants.MATCH_DISPLAY__AWAY_HOME
  ];

  useEffect(() => {
    // Assuming 1 bet range only
    let maxWidthAvailable = Constants.BET_DISTRIBUTION__MAX_WIDTH;
    let widthLeft = maxWidthAvailable;

    if(xHome && xTie && xAway) {
      // 3 existing bet ranges
      maxWidthAvailable = Constants.BET_DISTRIBUTION__3_OPT__MAX_WIDTH;
    } else if (
      (xHome && xTie) ||
      (xHome && xAway) ||
      (xTie && xAway)
    ) {
      // 2 existing bet ranges
      maxWidthAvailable = Constants.BET_DISTRIBUTION__2_OPT__MAX_WIDTH;
    }

    // Set final widths within range
    let homeWidth = whitinBetDistributionRange(
      xHome / xTotal,
      Constants.BET_DISTRIBUTION__MIN_WIDTH,
      maxWidthAvailable
    );

    widthLeft -= homeWidth;
  
    let tieWidth = whitinBetDistributionRange(
      xTie / xTotal,
      Constants.BET_DISTRIBUTION__MIN_WIDTH,
      ( widthLeft < maxWidthAvailable ? widthLeft : maxWidthAvailable) // Check if the width left is smaller than the width available
    );

    widthLeft -= tieWidth;

    let awayWidth = whitinBetDistributionRange(
      xAway / xTotal,
      Constants.BET_DISTRIBUTION__MIN_WIDTH,
      ( widthLeft < maxWidthAvailable ? widthLeft : maxWidthAvailable) // Check if the width left is smaller than the width available
    );

    if (xTotal > 0) {
      // Final check widths are correct
      while ((homeWidth + tieWidth + awayWidth) !== Constants.BET_DISTRIBUTION__MAX_WIDTH) {
        let fixedFlag = 0;
        const maxOption = Math.max(xHome, xTie, xAway);

        // Too much width, we need to cut down
        if((homeWidth + tieWidth + awayWidth) > Constants.BET_DISTRIBUTION__MAX_WIDTH) {
          // Fix home
          if (xHome === maxOption) {
            homeWidth -= 1;
            fixedFlag = 1; // We flag as soon as we do a change, to avoid reducing too much
          }

          // Fix tie
          if (xTie === maxOption && !fixedFlag) {
            tieWidth -= 1;
            fixedFlag = 1;
          }

          // Fix away
          if (xAway === maxOption && !fixedFlag) {
            awayWidth -= 1;
          }
        } else {
          // Too little width, we need to add up
          // Fix home
          if (xHome === maxOption) {
            homeWidth += 1;
            fixedFlag = 1; // We flag as soon as we do a change, to avoid reducing too much
          }

          // Fix tie
          if (xTie === maxOption && !fixedFlag) {
            tieWidth += 1;
            fixedFlag = 1;
          }

          // Fix away
          if (xAway === maxOption && !fixedFlag) {
            awayWidth += 1;
          }
        }
      }
    }

    // Set the final widths
    setHomeWidth(homeWidth);
    setTieWidth(tieWidth);
    setAwayWidth(awayWidth);

  }, [xHome, xTie, xAway])

  /////** DYSPLAY FORMAT HANDLERS **/////

  /**
   * Gets the width of the slot indicated, based on the display format
   * @param {String} slot - id of the slot being processed
   * @returns {Number} width
   */
  const getSlotWidth = (slot) => {
    if (MATCH_DISPLAYS_AWAY_FIRST.includes(matchDisplay)) {
      // If the format is AWAY-HOME, the first slot is the away team
      return (slot === Constants.MATCH_1X2__SLOT_ONE) ?
        awayWidth :
        homeWidth
    }

    // Default is HOME-AWAY
    return (slot === Constants.MATCH_1X2__SLOT_ONE) ?
      homeWidth :
      awayWidth
  }

  /**
   * Gets the width of the slot indicated, based on the display format
   * @param {String} slot - id of the slot being processed
   * @returns {Number} width
   */
  const getSlotxPoints = (slot) => {
    if (MATCH_DISPLAYS_AWAY_FIRST.includes(matchDisplay)) {
      // If the format is AWAY-HOME, the first slot is the away team
      return (slot === Constants.MATCH_1X2__SLOT_ONE) ?
        (xAway / xTotal) :
        (xHome / xTotal)
    }

    // Default is HOME-AWAY
    return (slot === Constants.MATCH_1X2__SLOT_ONE) ?
      (xHome / xTotal) :
      (xAway / xTotal)
  }

  /////** END OF DYSPLAY FORMAT HANDLERS **/////

  /**
   * Returns a number within a range
   * @param {Number} xPts - initial value
   * @param {Number} min - minimum value of the range
   * @param {Number} max - maximum value of the range
   * @returns {void}
   */
  const whitinBetDistributionRange = (xPts, min, max) => {
    if (xPts === 0) {
      return 0;
    }

    // Check possible mistakes: respect the min over the max
    if (min > max) {
      return min;
    }

    const initial = Math.floor(Constants.BET_DISTRIBUTION__MAX_WIDTH * xPts);

    if (initial > max) {
      return max;
    }

    if (initial < min) {
      return min;
    }

    return initial;
  }

  /**
   * Sets the color of the bet, depending on the right bet and the users bet
   * @param {String} betOption - bet being rendered
   * @returns {void}
   */
  const setBetDistributionStyle = (betOption) => {
    const { color, backgroundColor } = setTeamButtonStyle(betOption);

    return { color, backgroundColor }
  }

  return (
    (homeWidth + tieWidth + awayWidth)  ? 
      (
        <Segment
          basic
          compact
          attached='bottom'
          textAlign='center'
          className={ isEndOfSegment ? 'no-side-padding bet-distribution' : 'no-side-padding bet-distribution no-end-of-segment' }
        >
          <Grid
            columns={3}
            className='no-side-margin'>
            { getSlotWidth(Constants.MATCH_1X2__SLOT_ONE) > 0 ?
              (
                <Grid.Column
                  width={getSlotWidth(Constants.MATCH_1X2__SLOT_ONE)}
                  verticalAlign='center'
                  className='bet-distribution-option very-reduced-padding'
                  style={setBetDistributionStyle(getSlotBetOption(Constants.MATCH_1X2__SLOT_ONE))}
                >
                  <Segment
                    basic
                    className='no-padding'
                  >
                    <Image
                      alt={getSlotFullName(Constants.MATCH_1X2__SLOT_ONE)}
                      avatar
                      src={`./img/teams/${getSlotCrestURI(Constants.MATCH_1X2__SLOT_ONE)}.png`}
                      className='logo-bet'
                    />
                    <span
                      className='text-bet'
                    >
                      {((getSlotxPoints(Constants.MATCH_1X2__SLOT_ONE)) * 100).toFixed(0) + '%'}
                    </span>
                  </Segment>
                </Grid.Column>
              ):
              (<></>)
            }
            { tieWidth > 0 ?
              (
                <Grid.Column
                  width={tieWidth}
                  verticalAlign='center'
                  className='bet-distribution-option very-reduced-padding'
                  style={setBetDistributionStyle(Constants.MATCH_BET__TIE)}
                >
                  <Segment
                    basic
                    className='no-padding'
                  >
                    <Icon
                      name='close'
                      size='big'
                      className='logo-bet'
                    />
                    <span
                      className='text-bet'
                    >
                      {((xTie / xTotal) * 100).toFixed(0) + '%'}
                    </span>
                  </Segment>
                </Grid.Column>
              ):
              (<></>)
            }
            { getSlotWidth(Constants.MATCH_1X2__SLOT_TWO) > 0 ?
              (
                <Grid.Column
                  width={getSlotWidth(Constants.MATCH_1X2__SLOT_TWO)}
                  verticalAlign='center'
                  className='bet-distribution-option very-reduced-padding'
                  style={setBetDistributionStyle(getSlotBetOption(Constants.MATCH_1X2__SLOT_TWO))}
                >
                  <Segment
                    basic
                    className='no-padding'
                  >
                    <Image
                      alt={getSlotFullName(Constants.MATCH_1X2__SLOT_TWO)}
                      avatar
                      src={`./img/teams/${getSlotCrestURI(Constants.MATCH_1X2__SLOT_TWO)}.png`}
                      className='logo-bet'
                    />
                    <span
                      className='text-bet'
                    >
                      {((getSlotxPoints(Constants.MATCH_1X2__SLOT_TWO)) * 100).toFixed(0) + '%'}
                    </span>
                  </Segment>
                </Grid.Column>
              ):
              (<></>)
            }
          </Grid>
        </Segment>
      ):
      (<></>)
    );
};

export default BetDistribution;