import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Modal, Header, Form, Segment, Input,
  Label, Button, Dropdown, Divider, Checkbox
} from 'semantic-ui-react'

// Custom Elements
import LogoutButton from "./LogOutButton"
import CompetitionSelector from "../Main/Aux/competitionSelector";
import Loading from "../Main/Loading";

// Api
import UserAPI from "../../api/user";
import LeagueAPI from "../../api/league";

// Controllers
import Constants from "../../constants/constants";
import ErrorMessages from "../../constants/errorMessages";
import Util from "../../util";

const UserProfile = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [isLoading, setLoading] = useState(true);
  const [isNewUser, setNewUser] = useState(false);
  const [oAuthId, setOAuthId] = useState('');
  const [username, setUsername] = useState('');
  const [country, setCountry] = useState('');
  const [error, setError] = useState({});
  const [competitions, setCompetitions] = useState([]);
  const [selectedCompetitions, setSelectedCompetitions] = useState([]);
  const [competitionActiveIndex, setCompetitionActiveIndex] = useState([]);
  const [subscribed, setSubscribed] = useState(true);
  const [email, setEmail] = useState('');

  useEffect(() => {
    const getUserMetadata = async () => {
      const domain = process.env.REACT_APP_AUTH0__DOMAIN;
  
      try {  
        if (user) {
          const accessToken = await getAccessTokenSilently();
          
          const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;
    
          const metadataResponse = await fetch(userDetailsByIdUrl, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          const { identities } = await metadataResponse.json();

          const id = identities[0].user_id;

          setOAuthId(id);
          const response = await UserAPI.getOAuthUser(id);

          const existingUser = response.data;

          if (existingUser) {
            // Existing user - proceed
            setLoggedUser(existingUser);

          } else {
            // Save the new user's email
            setEmail(user.email);

            // Set default username based on Google Profile
            if(user['nickname']) {
              setUsername(user.nickname.substring(0, Constants.USER__MAX_LENGTH_NAME));              
            } else {
              const nickname = user.email.substring(0, user.email.indexOf('@'));
              setUsername(nickname.substring(0, Constants.USER__MAX_LENGTH_NAME));
            }

            // Load available competitions from DDBB
            const loadCompetitions = await LeagueAPI.getCompetitions();
            setCompetitions(loadCompetitions);

            // Open Sign Up Form
            setLoading(false);
            setNewUser(true);
            
          }
        }
      } catch (error) {
        Util.handleError(error, '');
      }
    };
  
    getUserMetadata();

  }, [getAccessTokenSilently, user?.sub]);

  const setLoggedUser = (user) => {
    // Save the userId in OAuth
    localStorage.setItem(Constants.SESSION__USER_TOKEN, user.token);
    localStorage.setItem(Constants.SESSION__USER_OBJ, JSON.stringify(user));

    // Check if we needed to redirect the user somewhere
    const redirect = localStorage.getItem(Constants.SESSION__REDIRECT_URL);

    if (redirect) {
      // Remove the redirect url to prevent unwanted redirections
      localStorage.removeItem(Constants.SESSION__REDIRECT_URL);

      // Fire META event
      window.fbq('trackCustom', Constants.META__SIGN_UP_EVENT);

      window.location.href = window.location.origin + redirect;
    } else {
      window.location.href = window.location.origin + Constants.NAV__GAMES_URI;
    }
  }

  const handleUserCreation = async () => {
    setLoading(true);
    
    let auxError = {};

    // Validate Username
    const nameAllowedCharacters = Constants.USER__NAME_ALLOWED_CHARS;

    if (username === '') {
        auxError.username = ErrorMessages.ERR_MSG__CREATE_USER__NAME_EMPTY;
    } else if (
      username.length < Constants.USER__MIN_LENGTH_NAME ||
      username.length > Constants.USER__MAX_LENGTH_NAME
    ) {
        auxError.username = ErrorMessages.ERR_MSG__CREATE_USER__NAME_INVALID_LENGTH;
    } else if (!nameAllowedCharacters.test(username)) {
        auxError.username = ErrorMessages.ERR_MSG__CREATE_USER__NAME_INVALID_CHARS;
    }

    // Validate Country
    if (country === '') {
      auxError.country = ErrorMessages.ERR_MSG__CREATE_USER__COUNTRY_EMPTY;
    }

    // If we didn't detect any errors, we can proceed
    if (!Object.keys(auxError).length) {
      try {
        const response = await UserAPI.createUser(
          {
              oAuthId,
              name: username,
              country,
              email,
              subscribedEmail: subscribed
          },
          selectedCompetitions,
        );

        // Check if the user was saved or not
        if (response.success) {
            // Set Logged User
            setNewUser(false);

            setLoggedUser(response.data.user);
        } else {
            // If we receive an array with error, it's a controlled rejection
            auxError = { ...response.data.error}
        }
    } catch(error) {
        // Fire error
        Util.handleError(error, 'Failed to create the user. Please retry');
    }
    }
    
    setLoading(false);
    setError(auxError);
  }

  return isLoading ?
    (
      <Loading />
    ) :
    (
      <Modal
        as={Form}
        id="create-user"
        error={Object.keys(error).length > 0}
        onSubmit={handleUserCreation}
        open={isNewUser}
        size='tiny'
      >
        <Modal.Header>
            Welcome to Ballkie!
        </Modal.Header>
        <Modal.Content scrolling>
            <Modal.Description>      
              <Segment basic>
                <p>
                  You are one step away from proving you are the Nostradamus of sports 
                </p>
                <Header>Profile Info</Header>
                <Form.Field
                  required
                  error={!!error.username}
                >
                  <Input
                      maxLength={Constants.USER__MAX_LENGTH_NAME} 
                      type='text'
                      placeholder='Select a war name'
                      label={<Label color='blue'>Username</Label>}
                      style={{maxWidth: '400px'}}
                      value={username}
                      onChange={event => setUsername(event.target.value)}
                  />
                  {error.username &&
                      <Label pointing prompt>{error.username}</Label>}
                </Form.Field>
                <Form.Field
                  required
                  error={!!error.country}
                >
                  <Dropdown
                    placeholder='Select Country'
                    fluid
                    search
                    selection
                    options={Constants.USER__COUNTRIES}
                    style={{maxWidth: '400px'}}
                    value={country}
                    onChange={(event, data) => setCountry(data.value)}
                  />
                  {error.country &&
                      <Label pointing prompt>{error.country}</Label>}
                </Form.Field>
                <Divider clearing />
                  <Header>
                    Which are your favourite competitions?
                  </Header>
                <Segment basic fluid className='no-padding'>
                    <p>
                      Missing a competition? Let us know <a href='https://forms.gle/U6ivhygYmgQyupkM7'>here</a>
                    </p>
                </Segment>
                <CompetitionSelector
                    competitions={competitions}
                    selectedCompetitions={selectedCompetitions}
                    setLeagueCompetitions={setSelectedCompetitions}
                    activeIndex={competitionActiveIndex}
                    setActiveIndex={setCompetitionActiveIndex}
                />
                <Divider hidden />
                <Checkbox
                  label='Notify me when I have pending matches to predict'
                  checked={subscribed}
                  onClick={() => setSubscribed(!subscribed)}
                />
              </Segment>
            </Modal.Description>
        </Modal.Content>
            <Modal.Actions>
                <LogoutButton
                  context={Constants.NAV__WELCOME_SECTION}
                />
                <Button
                    content="Create"
                    type="submit"
                    form="create-user"
                    positive
                />
            </Modal.Actions>
        </Modal>
    )
};

export default UserProfile;