import { useEffect, useState, createRef } from "react";
import { Container, Header, Segment, GridColumn, Checkbox, Card, Divider, Icon } from 'semantic-ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRobot } from '@fortawesome/free-solid-svg-icons'

// API
import UserAPI from "../api/user";

// Components
import Util from "../util";
import Constants from "../constants/constants";
import Loading from "../components/Main/Loading";
import LogoutButton from "../components/Log In/LogOutButton";

const Settings = () => {
  const [user, setUser] = useState({});
  const [hasPickSaver, setPickSaver] = useState(false);
  const [subscribedPush, setSubscribedPush] = useState(false);
  const [subscribedEmail, setSubscribedEmail] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [settingLoading, setSettingLoading] = useState(false);

  useEffect(() => {
    // Reset loader
    setLoading(true);

    const userString = localStorage.getItem(Constants.SESSION__USER_OBJ);

    const user = JSON.parse(userString);

    setPickSaver(user.hasPickSaver ?? false);
    setSubscribedEmail(user.subscribedEmail ?? false);
    setSubscribedPush(user.subscribedPush ?? false)

    setUser(user);
    setLoading(false); // Skip the UseState load

  }, []);

  const updateUser = async (user) => {
    localStorage.setItem(Constants.SESSION__USER_OBJ, JSON.stringify(user));
    setUser(user);
  }

  const handleSetPickSaver = async (newValue) => {
    setSettingLoading(true);
    
    try {
      // Subscribe the user to the pick saver
      const response = await UserAPI.setPickSaver(newValue);

      if (response.success) {
        // Update vars in the front end
        setPickSaver(newValue);
        user.hasPickSaver = newValue;
        updateUser(user);
      } else {
        Util.handleError(null, 'Something went wrong. Please try again');
      }
    } catch(error) {
      // Fire error
      Util.handleError(error, 'Something went wrong. Please retry');
    }

    setSettingLoading(false);
  }

  const handleSetEmailNotification = async (newValue) => {
    setSettingLoading(true);
    
    try {
      // Subscribe the user to the pick saver
      const response = await UserAPI.setEmailSubscription(newValue);

      if (response.success) {
        // Update vars in the front end
        setSubscribedEmail(newValue);
        user.subscribedEmail = newValue;
        updateUser(user);
      } else {
        Util.handleError(null, 'Something went wrong. Please try again');
      }
    } catch(error) {
      // Fire error
      Util.handleError(error, 'Something went wrong. Please retry');
    }

    setSettingLoading(false);
  }

  return isLoading ?
    (
      <Loading/>
    ) :
    (
      <>
        <Container style={{textAlign: '-webkit-center'}}>
          <Segment
            inverted
            className='main-background'
          >
            <Header as='h3' style={{overflow: 'hidden'}}>
              Settings
            </Header>
          </Segment>
          { settingLoading && (<Loading/>) }
          <GridColumn>
            <Card fluid style={{ padding: '10px' }}>
              <Card.Content>
                <Card.Header textAlign="left">
                  <FontAwesomeIcon icon={faRobot} style={{ paddingRight: '4px' }}/>   
                  Pick Saver
                </Card.Header>
                <Card.Description textAlign="left">
                  Pick Saver steps in if you forget to save your pick for a match, picking
                  for you to help you save some points!
                  <Divider hidden />
                  <Checkbox toggle
                    label='Pick Saver'
                    checked={hasPickSaver}
                    onClick={() => handleSetPickSaver(!hasPickSaver)}
                  />
                </Card.Description>
              </Card.Content>
            </Card>
            <Card fluid style={{ padding: '10px' }}>
              <Card.Content>
                <Card.Header textAlign="left">
                  <Icon name='mail' />
                  Notifications
                </Card.Header>
                <Card.Description textAlign="left">
                  We'll alert you when you have pending matches within the next 24h without a prediction
                  (max. 1 message per day)
                  <Divider hidden />
                  <Checkbox toggle
                    label='Email'
                    checked={subscribedEmail}
                    onClick={() => handleSetEmailNotification(!subscribedEmail)}
                  />
                  <br /><br />
                  <Checkbox toggle
                    label='Push (only available in the app)'
                    checked={subscribedPush}
                    disabled
                  />
                </Card.Description>
              </Card.Content>
            </Card>
            <LogoutButton
                  context={Constants.NAV__MORE_SECTION}
                />
          </GridColumn>
        </Container>
      </>
        
    )
    

};

export default Settings;
