import React, {useState, useEffect} from 'react';
import {
  Segment, Grid, GridColumn, Icon, Label
} from 'semantic-ui-react'
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRobot } from '@fortawesome/free-solid-svg-icons'


// styles
import "../../../styles/global.css";
import "../../../styles/match.css";
import "../../../styles/competitions.css";

// Components
import MatchStatus from "../MatchStatus"
import Constants from "../../../constants/constants";
import BetDistribution from "../BetDistribution"

// API
import BetAPI from "../../../api/bet";
import Util from "../../../util";

/**
 * Element formed by SLOT ONE - TIE - SLOT TWO
 * Based on the display format of the competition, each slot will be populated
 * with the home or away team, in order to support HOME-AWAY and AWAY-HOME formats
 */
const Match1X2 = ({ match, filterBets, filterCompetition, showBetDistribution, isBetScope }) => {
  const [hidden, setHidden] = useState(false);
  const [currentBet, setBet] = useState('');
  const [disabledButtons, setDisabledButtons] = useState(false);
  const [xHome, setXHome] = useState(match?.betDistribution?.home ?? 0);
  const [xTie, setXTie] = useState(match?.betDistribution?.tie ?? 0);
  const [xAway, setXAway] = useState(match?.betDistribution?.away ?? 0);

  // Set buttons ids
  const homeButtonId = Constants.MATCH_BET__HOME + '-' + match._id;
  const tieButtonId = Constants.MATCH_BET__TIE + '-' + match._id;
  const awayButtonId = Constants.MATCH_BET__AWAY + '-' + match._id;

  const { competition, homeTeam, awayTeam, bet } = match;
  const { matchDisplay } = competition;

  // Array with displays that have the away team first
  const MATCH_DISPLAYS_AWAY_FIRST = [
    Constants.MATCH_DISPLAY__AWAY_X_HOME,
    Constants.MATCH_DISPLAY__AWAY_HOME
  ];

  // Array with displays that have a tie option
  const MATCH_DISPLAYS_WITHOUT_TIE_OPTION = [
    Constants.MATCH_DISPLAY__AWAY_HOME,
    Constants.MATCH_DISPLAY__HOME_AWAY
  ];

  // Team Slots
  const MATCH_1X2_TEAM_SLOTS = [
    Constants.MATCH_1X2__SLOT_ONE,
    Constants.MATCH_1X2__SLOT_TWO
  ];

  useEffect(() => {
    if (bet) {
      setBet(bet.bet);
    }

    if (!isBetScope || match.status === Constants.MATCH_STATUS__POSTPONED) {
      setDisabledButtons(true);
    }
  }, [bet]);

  useEffect(() => {
    let hidden = false;

    // If the filter per pending bets is enabled, we hide the match
    if (filterBets && currentBet) {
      hidden = true
    } else {
      // If there's no bet or no filter on bets, we filter per competition
      if (filterCompetition) {
        if (filterCompetition !== match.competition._id) {
          hidden = true;
        }
      }
    }

    setHidden(hidden);
  }, [filterBets, filterCompetition])

  /////** DYSPLAY FORMAT HANDLERS **/////

  /**
   * Gets the button Id of the slot indicated, based on the display format
   * @param {String} slot - id of the slot being processed
   * @returns {String} button id
   */
  const getSlotButtonId = (slot) => {
    if (MATCH_DISPLAYS_AWAY_FIRST.includes(matchDisplay)) {
      // If the format is AWAY-HOME, the first slot is the away team
      return (slot === Constants.MATCH_1X2__SLOT_ONE) ?
        awayButtonId :
        homeButtonId
    }

    // Default is HOME-AWAY
    return (slot === Constants.MATCH_1X2__SLOT_ONE) ?
      homeButtonId :
      awayButtonId
  }

  /**
   * Gets the bet option of the slot indicated, based on the display format
   * @param {String} slot - id of the slot being processed
   * @returns {String} bet option
   */
  const getSlotBetOption = (slot) => {
    if (MATCH_DISPLAYS_AWAY_FIRST.includes(matchDisplay)) {
      // If the format is AWAY-HOME, the first slot is the away team
      return (slot === Constants.MATCH_1X2__SLOT_ONE) ?
        Constants.MATCH_BET__AWAY :
        Constants.MATCH_BET__HOME
    }

    // Default is HOME-AWAY
    return (slot === Constants.MATCH_1X2__SLOT_ONE) ?
      Constants.MATCH_BET__HOME :
      Constants.MATCH_BET__AWAY
  }

  /**
   * Gets the full name of the team in the slot indicated, based on the display format
   * @param {String} slot - id of the slot being processed
   * @returns {String} full name of the team
   */
  const getSlotFullName = (slot) => {
    if (MATCH_DISPLAYS_AWAY_FIRST.includes(matchDisplay)) {
      // If the format is AWAY-HOME, the first slot is the away team
      return (slot === Constants.MATCH_1X2__SLOT_ONE) ?
        awayTeam.name :
        homeTeam.name
    }

    // Default is HOME-AWAY
    return (slot === Constants.MATCH_1X2__SLOT_ONE) ?
      homeTeam.name :
      awayTeam.name
  }

  /**
   * Gets the URI of the logo of the team in the slot indicated, based on the display format
   * @param {String} slot - id of the slot being processed
   * @returns {String} crest uri of the team in the indicated slot
   */
  const getSlotCrestURI = (slot) => {
    if (MATCH_DISPLAYS_AWAY_FIRST.includes(matchDisplay)) {
      // If the format is AWAY-HOME, the first slot is the away team
      return (slot === Constants.MATCH_1X2__SLOT_ONE) ?
        ( awayTeam.crestURI ? awayTeam.crestURI : Constants.MATCH_DEFAULT_TEAM__LOGO ) :
        ( homeTeam.crestURI ? homeTeam.crestURI : Constants.MATCH_DEFAULT_TEAM__LOGO )
    }

    // Default is HOME-AWAY
    return (slot === Constants.MATCH_1X2__SLOT_ONE) ?
      ( homeTeam.crestURI ? homeTeam.crestURI : Constants.MATCH_DEFAULT_TEAM__LOGO ) :
      ( awayTeam.crestURI ? awayTeam.crestURI : Constants.MATCH_DEFAULT_TEAM__LOGO )
  }

  /**
   * Gets the short name of the team in the slot indicated, based on the display format
   * @param {String} slot - id of the slot being processed
   * @returns {String} short name of the team in the indicated slot
   */
  const getSlotShortName = (slot) => {
    if (MATCH_DISPLAYS_AWAY_FIRST.includes(matchDisplay)) {
      // If the format is AWAY-HOME, the first slot is the away team
      return (slot === Constants.MATCH_1X2__SLOT_ONE) ?
        awayTeam.shortName :
        homeTeam.shortName
    }

    // Default is HOME-AWAY
    return (slot === Constants.MATCH_1X2__SLOT_ONE) ?
      homeTeam.shortName :
      awayTeam.shortName
  }

  /**
   * Gets the score of the team in the slot indicated, based on the display format
   * @param {String} slot - id of the slot being processed
   * @returns {String} score of the team in the indicated slot
   */
  const getSlotResult = (slot) => {
    if (MATCH_DISPLAYS_AWAY_FIRST.includes(matchDisplay)) {
      // If the format is AWAY-HOME, the first slot is the away team
      return (slot === Constants.MATCH_1X2__SLOT_ONE) ?
        match?.result?.away :
        match?.result?.home
    }

    // Default is HOME-AWAY
    return (slot === Constants.MATCH_1X2__SLOT_ONE) ?
      match?.result?.home :
      match?.result?.away
  }

  /**
   * Gets the width of box reserved to the slot
   * @param {String} slot - id of the slot being processed
   * @returns {String} score of the team in the indicated slot
   */
  const getSlotWidth = (slot) => {
    if (MATCH_1X2_TEAM_SLOTS.includes(slot)) {
      if(isBetScope) {
        if (MATCH_DISPLAYS_WITHOUT_TIE_OPTION.includes(matchDisplay)) {
          // If there's no tie, team slots can use more space
          return 7;
        }

        return 6;
      } else {
        if (MATCH_DISPLAYS_WITHOUT_TIE_OPTION.includes(matchDisplay)) {
          // If there's no tie, team slots can use more space
          return 7;
        }

        return 7;
      }

    } else {
      // Tie slot
      if(isBetScope) {
        if (MATCH_DISPLAYS_WITHOUT_TIE_OPTION.includes(matchDisplay)) {
          // If there's no tie, team slots can use more space
          return 2;
        }

        return 4;
      }

      return 2;
    }
  }

  /////** END OF DYSPLAY FORMAT HANDLERS **/////

  /**
   * Determines if we need to show the tie option or not
   * @returns {Boolean} either to show or to hide the bet option
   */
  const showTieOption = () => {
    if (MATCH_DISPLAYS_WITHOUT_TIE_OPTION.includes(matchDisplay)) {
      return false;
    }

    return true;
  }

  /**
   * Gets the option betted by the user for this match
   * @param {String} buttonId - id of the button clicked
   * @returns {void}
   */
  const getBetOption = (buttonId) => {
    const selected = buttonId.substring(0, buttonId.indexOf('-'));
    
    if (currentBet === selected) {
      return Constants.MATCH_BET__RESET;
    }

    return selected;
  }

  /**
   * Sets the new bet based on the reply from the server
   * @param {String} bet - new bet
   * @returns {void}
   */
  const setNewBet = (bet) => {
    if (bet === Constants.MATCH_BET__EXPIRED) {
      Util.handleError(null, 'The match is now in progress, we cant save your prediction');
      return;
    }

    if (bet === Constants.MATCH_BET__RESET) {
      setBet('');
    } else {
      setBet(bet);
    }
  }

  /**
   * Set style for the team button
   * @param {String} betOption - id of the button clicked
   * @returns {void}
   */
  const setTeamButtonStyle = (betOption) => {
    let styleOptions = {};
    
    // Set up colors
    const homeMain = homeTeam.colors ? homeTeam.colors.main : Constants.MATCH_DEFAULT_TEAM__COLORS_MAIN;
    const homeAccent = homeTeam.colors ? homeTeam.colors.accent : Constants.MATCH_DEFAULT_TEAM__COLORS_ACCENT;

    const awayMain = awayTeam.colors ? awayTeam.colors.main : Constants.MATCH_DEFAULT_TEAM__COLORS_MAIN;
    const awayAccent = awayTeam.colors ? awayTeam.colors.accent : Constants.MATCH_DEFAULT_TEAM__COLORS_ACCENT;

    // Is it a past match?
    if (!isBetScope && match.status === Constants.MATCH_STATUS__FINISHED) {
      styleOptions.border = '0px';

      // Was there a bet placed?
      if (bet) {
        // Correct bet
        if (bet.bet === betOption && bet.points) {
          styleOptions.backgroundColor = Constants.BET_RESULT_COLORS__CORRECT_MAIN;
          styleOptions.color = Constants.BET_RESULT_COLORS__CORRECT_ACCENT;
        } else if (bet.bet === betOption && !bet.points){
          // Incorrect bet
          styleOptions.backgroundColor = Constants.BET_RESULT_COLORS__INCORRECT_MAIN;
          styleOptions.color = Constants.BET_RESULT_COLORS__INCORRECT_ACCENT;
        } else {
          // Bet for other option: not selected button
          styleOptions.backgroundColor = '#E0E2DB';
          styleOptions.color = 'grey';
        }
      } else {
        // Bet for other option: not selected button
        styleOptions.backgroundColor = '#E0E2DB';
        styleOptions.color = 'grey';
      }
    } else {
      // No Bet or Bet the current button: main colors
      if (currentBet === '' || currentBet === betOption) {
        styleOptions.backgroundColor =
          (betOption === Constants.MATCH_BET__HOME) ? 
            homeMain :
            (betOption === Constants.MATCH_BET__TIE) ? 
              Constants.MATCH_TIE_COLORS__MAIN :
              awayMain
    
        styleOptions.color =
          (betOption === Constants.MATCH_BET__HOME) ? 
            homeAccent :
            (betOption === Constants.MATCH_BET__TIE) ? 
              Constants.MATCH_TIE_COLORS__ACCENT :
              awayAccent

        if (currentBet === betOption) {
          styleOptions.border = '3px solid ' + styleOptions.color;
        }
      } else {
        // Bet for other option: not selected button
        styleOptions.backgroundColor = '#E0E2DB';
        styleOptions.color = 'grey';
        styleOptions.border = '1px solid ' + '#CCCCCC';
      }
    }

    return styleOptions;
  }

  /**
   * Gets the button Id of the slot indicated, based on the display format
   * @param {String} slot - id of the slot being processed
   * @returns {String} button id
   */
  const stringifyBet = () => {
    if (bet) {
      if (bet.bet === Constants.MATCH_BET__HOME) {
        return homeTeam.name
      }

      if (bet.bet === Constants.MATCH_BET__AWAY) {
        return awayTeam.name
      }

      if (bet.bet === Constants.MATCH_BET__TIE) {
        return 'tie'
      }
    }

    return '';
  }

  /**
   * Handler called when placing a bet
   * @param {String} buttonId - id of the button clicked
   * @returns {void}
   */
  const handleBet = async (buttonId) => {
    try {
      const bet = getBetOption(buttonId);

      // Disable buttons of the match to prevent re-bets while saving
      setDisabledButtons(true);

      // Set loading class until value is saved
      const selectedButton = document.getElementById(buttonId);
      selectedButton.classList.add("loading");

      const now = moment().toDate();
      const matchTime = moment(match.time).toDate();

      if(now < matchTime) {

        // API call to save bet
        const reply = await BetAPI.upsertBet(match, bet);

        setDisabledButtons(false);

        if (!reply.success) {
          Util.handleError(null, 'We couldn\'t save your bet, please retry');
        } else {
          setNewBet(reply.data.newBet);
          const { home, tie, away } = reply.data.newBetDistribution;

          setXHome(home ?? 0);
          setXTie(tie ?? 0);
          setXAway(away ?? 0);
        }

      } else {
        Util.handleError(null, 'The match is now in progress, we cant save your bet');
      }

      selectedButton.classList.remove("loading");
    } catch(error) {        
      // Stop Loading
      const selectedButton = document.getElementById(buttonId);
      selectedButton.classList.remove("loading");

      // Fire error
      Util.handleError(error, 'We couldn\'t save your bet, please retry');
  }
    
  };

  return (
    hidden ?
      (<></>) :
      (
        <>
          <MatchStatus
            status={
              (match.status === Constants.MATCH_STATUS__SCHEDULED && !isBetScope) ?
                Constants.MATCH_STATUS__PLAYING :
                match.status
            }
            time={match.localTime}
            sport={competition?.sport?.icon}
            ko={match.ko}
            bet={currentBet}
            points={bet?.points}
            includesET={match.includesET}
            bonusPoints={match.bonusPoints}
            competition={competition}
          />
          <Segment
            attached
            raised
            className={ match.status ===  Constants.MATCH_STATUS__FINISHED ? 'match' : ''}
          >
            <Grid>
              <GridColumn
                width={getSlotWidth(Constants.MATCH_1X2__SLOT_ONE)}
                className='no-padding'
              >
                <button
                  id={getSlotButtonId(Constants.MATCH_1X2__SLOT_ONE)}
                  onClick={() => handleBet(getSlotButtonId(Constants.MATCH_1X2__SLOT_ONE))}
                  disabled={disabledButtons}
                  className='ui button match-option local no-margin'
                  style={setTeamButtonStyle(getSlotBetOption(Constants.MATCH_1X2__SLOT_ONE))}
                >
                  <Grid
                    columns={!isBetScope ? 3 : 2 }
                    verticalAlign='middle'
                    className='visible no-margin'
                  >  
                    <GridColumn
                      className='no-padding text-align-right'
                    >
                      <img
                        alt={getSlotFullName(Constants.MATCH_1X2__SLOT_ONE)}
                        width={30}
                        src={`./img/teams/${getSlotCrestURI(Constants.MATCH_1X2__SLOT_ONE)}.png`}
                      />
                    </GridColumn>
                    <GridColumn
                      className='no-padding text-align-left'
                    >
                      <span className="team-name">
                        {getSlotShortName(Constants.MATCH_1X2__SLOT_ONE)}
                      </span>
                    </GridColumn>
                    { !isBetScope ?
                      (
                        <GridColumn
                          className='no-padding'
                        >
                          <span style={{fontSize: '20px', color: 'black' }}>
                            {getSlotResult(Constants.MATCH_1X2__SLOT_ONE)}
                          </span>
                        </GridColumn>
                      ) :
                      (
                        <></>
                      )
                    }
                  </Grid>
                </button>  
              </GridColumn>
              <GridColumn
                  width={getSlotWidth(Constants.MATCH_1X2__SLOT_TIE)}
                  className='no-padding'
              >
                { showTieOption() ?
                  (
                    <button
                      id={tieButtonId}
                      onClick={() => handleBet(tieButtonId)}
                      disabled={disabledButtons}
                      className='ui button match-option no-margin'
                      style={setTeamButtonStyle(Constants.MATCH_BET__TIE)}
                    >
                      <Icon
                        name='close'
                        size='big'
                        className='no-margin'
                      />
                    </button>
                  ) :
                  (
                    <button
                      id={tieButtonId}
                      disabled={disabledButtons}
                      className='ui button vs-separator match-option no-margin'
                      style={{backgroundColor:'black',color:'white'}}
                    >
                      VS
                    </button>
                  )
                }
              </GridColumn>
              <GridColumn
                  width={getSlotWidth(Constants.MATCH_1X2__SLOT_TWO)}
                  className='no-padding'
              >
                <button
                  id={getSlotButtonId(Constants.MATCH_1X2__SLOT_TWO)}
                  onClick={() => handleBet(getSlotButtonId(Constants.MATCH_1X2__SLOT_TWO))}
                  disabled={disabledButtons}
                  className='ui button match-option away no-margin'
                  style={setTeamButtonStyle(getSlotBetOption(Constants.MATCH_1X2__SLOT_TWO))}
                >
                  <Grid
                    columns={ !isBetScope ? 3 : 2 }
                    verticalAlign='middle'
                    className='no-margin'
                  >
                    { !isBetScope ?
                      (
                        <GridColumn
                          className='no-padding'
                        >
                          <span style={{fontSize: '20px', color:'black'}}>
                            {getSlotResult(Constants.MATCH_1X2__SLOT_TWO)}
                          </span>
                        </GridColumn>
                      ) :
                      (
                        <></>
                      )
                    }
                    <GridColumn
                      className='no-padding text-align-right'
                    >
                      <img
                        alt={getSlotFullName(Constants.MATCH_1X2__SLOT_TWO)}
                        width={30}
                        src={`./img/teams/${getSlotCrestURI(Constants.MATCH_1X2__SLOT_TWO)}.png`}
                      />
                    </GridColumn>
                    <GridColumn
                      className='no-padding text-align-left'
                    >
                      <span className="team-name">
                        {getSlotShortName(Constants.MATCH_1X2__SLOT_TWO)}
                      </span>
                    </GridColumn>
                  </Grid>
                </button>  
              </GridColumn>
            </Grid>
          </Segment>
          {
            showBetDistribution &&
              (
                <BetDistribution
                  xHome={xHome ?? 0}
                  xTie={xTie ?? 0}
                  xAway={xAway ?? 0}
                  matchDisplay={matchDisplay}
                  getSlotBetOption={getSlotBetOption}
                  getSlotFullName={getSlotFullName}
                  getSlotCrestURI={getSlotCrestURI}
                  setTeamButtonStyle={setTeamButtonStyle}
                  isEndOfSegment={!bet?.autoPick}
                />
              )
          }
          {
            bet?.autoPick && (
              <Segment
                basic
                compact
                attached='bottom'
                textAlign='center'
                className='no-padding bet-distribution'
              >
                <Label basic size='medium'>
                  <FontAwesomeIcon icon={faRobot} style={{ paddingRight: '4px' }}/>   
                  {'You forgot to pick. Ballkie picked ' + stringifyBet() + ' for you'}
                </Label>
              </Segment>
            )

          }
        </>
      )
  );
};

export default Match1X2;
